<template>
  <v-container pa-0 ma-0 fluid>
        <v-layout align-center justify-center v-if="loadingLogs">
          <a-spin class="mt-2" tip="Loading..." />
        </v-layout>
    <v-row no-gutters v-if="!loadingLogs">
      <v-col  :sm="12" :xs="12" :md="6">
        <v-card
          :class="seeDetails ? 'pa-1 ml-8 mt-1' : 'mx-auto text-center'"
          style="background-color: #0f3057; width:90%"
          dark
          
        >
          <v-card-text>
            <v-sheet color="rgba(0, 0, 0, .12)">
              <barchart
                v-if="isLoadBar"
               style="width:100%;"
               :height="isTablet ? screenSize-650:SizeName=='xl'? screenSize-600 : screenSize - 370"
                :data="chartData"
                :options="chartOptions"
              ></barchart>
            </v-sheet>
          </v-card-text>

          <v-card-text>
            <div class="display-1 font-weight-thin">User Logs</div>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-col>
      <v-col >
        <!-- search: {{search}}
         <br>
        month: {{selectedMonth}} -->
        <v-layout align-center justify-center row wrap>
         
          <a-input  style="width: 40%" addon-before="Search" v-model="search" class="mb-1 mt-1"></a-input>
          <span class="ml-3 mr-1">Filter By Month:</span>
          
          <a-select  style="width: 30%" v-model="selectedMonth" @change="ChangeMonth(selectedMonth)">
            <a-select-option
              v-for="item in monthsOpt"
              :key="item.value"
              v-value="item.value"
              >{{ item.text }}</a-select-option
          > </a-select>
         
        <a-table
          style="width: 80%"
          :data-source="logsPaginatedData"
          :columns="header"
          bordered
          :scroll="{ y: screenSize - 230 }"
          :pagination="false"
        >
        </a-table>
        </v-layout>
      </v-col>
      <v-col :sm="12" :xs="12" :md="6"> </v-col>
      <v-col cols="6">
        <v-pagination
          v-model="LogsPageNo"
          :length="logsPageCount"
          :total-visible="5"
          dark
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          v-if="!loadingLogs"
        ></v-pagination>    
    </v-col>
    </v-row>
 
    <!-- </v-layout> -->
  </v-container>
</template>
<script>
import barchart from "../barchart.js";
import axios from "axios";
import moment from 'moment'
import _ from 'lodash'

const header = [
  {
    title: "#",
    dataIndex: "rowNumber",
    key: "rowNumber",
    width:60
  },
  {
    title: "User",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Total Logs",
    dataIndex: 'totalLogs',
    key: 'totalLogs',
    width:110
  },
];

export default {
  components: {
    barchart,
  },
  data: () => ({
    currentYear: "",
    selectedMonth: '',
    loadingLogs: false,
    LogsPageNo: 1,
    search:'',
    rowLogsCount: 30,
    header,
    isLoadBar: false,
    Logs: [{ user: "", count: 0 }],
    labels: [],
    value: [],
    seeDetails: false,
    AllLogs: [],
    chartOptions: {
      hoverBorderWidth: 20,
       scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
    },
    chartData: {
      hoverBackgroundColor: "red",
      hoverBorderWidth: 10,
      labels: [],
      maintainAspectRatio: false,
      datasets: [
        {
          label: "Logs",
          backgroundColor: [],
          data: [],
          responsive: true,
          maintainAspectRatio: false,
         
        },
      ],
     
    },
    gData: []
  }),
  created() {
    this.currentYear= moment().format("YYYY");
    this.selectedMonth = moment().format("MMMM");
    this.loadLogsNew(); // 2022-02-07

    // this.loadLogs();
  },
  methods: {
    ChangeMonth(month) {    // 2022-02-07
       this.AllLogs = []
       this.loadingLogs = true
        axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        const url =  `${this.api}userLogs/getAllUserLogs/${this.currentYear}/${month}`
        axios.get(url).then(res=>{

          this.AllLogs = _.orderBy( res.data, 'totalLogs' , 'desc')
            .map((rec,i)=>{
            return {
              key: i+1,
              rowNumber: i+1,
              MailAddress: rec.MailAddress,
              totalLogs: rec.totalLogs,
              name: rec.name // 2022-02-15
            }
          })

          let bardata = {};
          bardata.label = "Logs";
          bardata.backgroundColor = [];
          bardata.data = [];
          this.chartData.labels = []
          this.AllLogs.map((rec,i) => {
            if(i <= 8){
            bardata.backgroundColor.push(this.getRandomColor());
            bardata.data.push(rec.totalLogs);
            bardata.responsive = true;
            bardata.maintainAspectRatio = false;
            bardata.pieceLabel = {};
            bardata.pieceLabel["mode"] = "percentage";
            bardata.pieceLabel["precision"] = 1;
            this.chartData.labels.push(rec.MailAddress);
            }
          });
            bardata.data.push(0);

          this.chartData.datasets[0] = bardata;

          this.isLoadBar = true;
          this.loadingLogs = false
        }).catch(err=>{ console.log(err.message)})
    },

    loadLogsNew(){  // 2022-02-07 tanskie
      this.loadingLogs = true
      let currentMonth = moment().format("MM")

      axios.defaults.headers.common["x-api-key"] =
      "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      const url =  `${this.api}userLogs/getAllUserLogs/${this.currentYear}/${currentMonth}`
      axios.get(url).then(res=>{

        this.AllLogs = _.orderBy( res.data, 'totalLogs' , 'desc')
          .map((rec,i)=>{
           return {
             key: i+1,
             rowNumber: i+1,
             MailAddress: rec.MailAddress,
             totalLogs: rec.totalLogs,
             name: rec.name // 2022-02-15
           }
        })


        let bardata = {};
        bardata.label = "Logs";
        bardata.backgroundColor = [];
        bardata.data = [];
        this.AllLogs.map((rec,i) => {
          if(i <= 8){
          bardata.backgroundColor.push(this.getRandomColor());
          bardata.data.push(rec.totalLogs);
          bardata.responsive = true;
          bardata.maintainAspectRatio = false;
          bardata.pieceLabel = {};
          bardata.pieceLabel["mode"] = "percentage";
          bardata.pieceLabel["precision"] = 1;
          this.chartData.labels.push(rec.MailAddress);
          }
        });
          bardata.data.push(0);

        this.chartData.datasets[0] = bardata;

        this.isLoadBar = true;
        this.loadingLogs = false
      }).catch(err=>{ console.log(err.message)})
    },

    getRandomColor() {
      const hue = Math.floor(Math.random() * 360);
      const randomColor = `hsl(${hue},70%,80%)`;
      return randomColor;
    },


    loadLogs() {
             
    //   axios.defaults.headers.common["x-api-key"] =
    //     "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
    //   let url = `${this.api}userlogs/getAllLogs`;
    //   axios.get(url).then((res) => {
    //     res.data.sort((a, b) => (a.count < b.count ? 1 : -1));
    //     let graphData = res.data
   // graphData = graphData.splice(0,8)
        let graphData = [
            {
                user: 'User 1',
                accessDates: [{date: '9/29/2021', count: 1},{date: '9/30/2021', count: 1},{date: '9/31/2021', count: 10},{date: '9/31/2021', count: 10},{date: '9/31/2021', count: 10}],
                id: '00000',
                employeeCode: '00000',
            },
            {
                user: 'User 2',
                accessDates: [{date: '9/29/2021', count: 1},{date: '9/30/2021', count: 1},{date: '9/31/2021', count: 10}],
                id: '11111',
                employeeCode: '11111',
            },
            {
                user: 'User 3',
                accessDates: [{date: '9/29/2021', count: 1},{date: '9/30/2021', count: 1},{date: '9/31/2021', count: 10},{date: '9/31/2021', count: 10}],
                id: '22222',
                employeeCode: '33333',
            },
            {
                user: 'User 4',
                accessDates: [{date: '9/29/2021', count: 1},{date: '9/30/2021', count: 1},{date: '9/31/2021', count: 10}],
                id: '33333',
                employeeCode: '33333',
            },
            //monthly - insert another record , daily update each record by day.
            
        ]
        this.gData = graphData.map(r => {
            r.count = r.accessDates.map(r => {
                console.log(r.count)
                return r.count
            }).reduce((a, b) => a + b, 0)
            return r
        }).sort((a, b) => (a.count < b.count ? 1 : -1))

        let bardata = {};
        bardata.label = "Logs";
        bardata.backgroundColor = [];
        bardata.data = [];
        this.gData.map((rec,i) => {
          if(i <=8){
          bardata.backgroundColor.push(this.getRandomColor());
          bardata.data.push(rec.count);
          bardata.responsive = true;
          bardata.maintainAspectRatio = false;
          bardata.pieceLabel = {};
          bardata.pieceLabel["mode"] = "percentage";
          bardata.pieceLabel["precision"] = 1;
          this.chartData.labels.push(rec.user);
          }
        });
          bardata.data.push(0);

        this.chartData.datasets[0] = bardata;
        // console.log(this.chartData);
        this.AllLogs = this.gData.map((r, i) => {
          r.key = i;
          return r;
        });
        
        this.AllLogs.map((r, i) => {
          r.num = i + 1;
          return r;
        });
        this.gData = this.gData.filter((r) => {
          return r.count != 1;
        });
        this.gData.sort((a, b) => (a.count < b.count ? 1 : -1));

        this.Logs = this.gData.sort().splice(0, 5);
        this.Logs.map((r) => {
          this.labels.push(r.searchWord);
          this.value.push(r.count);
        });
        this.isLoadBar = true;
        this.loadingLogs = false
    //   });
    },
  },
  computed: {
    monthsOpt(){
      return [
        {text: 'January', value: '1'},
        {text: 'February', value: '2'},
        {text: 'March', value: '3'},
        {text: 'April', value: '4'},
        {text: 'May', value: '5'},
        {text: 'June', value: '6'},
        {text: 'July', value: '7'},
        {text: 'August', value: '8'},
        {text: 'September', value: '9'},
        {text: 'October', value: '10'},
        {text: 'November', value: '11'},
        {text: 'December', value: '12'}

      ]
    },
      getCount(){
          return this.gData
      },
    logsPageCount() {
      let l = this.filteredLogs.length,
        s = this.rowLogsCount;
      return Math.ceil(l / s);
    },
     logsPaginatedData() {
      const start = (this.LogsPageNo - 1) * this.rowLogsCount,
        end = start + this.rowLogsCount;
      return this.filteredLogs.slice(start, end);
    },
    SizeName(){
       return this.$vuetify.breakpoint.name;
    },
    isTablet(){
      if(this.$vuetify.breakpoint.name == 'sm' || this.$vuetify.breakpoint.name == 'xs'){
        return true
      }
      else return false
    },
    WidthSize() {
      return this.$vuetify.breakpoint.width;
    },
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
    filteredLogs(){
      if(this.search){
        return this.AllLogs.filter(rec=>{
          let search = new RegExp(this.search.toUpperCase(), "g");
          return JSON.stringify(Object.values(rec)).toUpperCase().match(search);
        })
      }
      else return this.AllLogs
    }
  },
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>